import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { PlotData } from 'library/models/plot';
import React, { useRef, useState } from 'react';
import { roleTypes } from '../../auth/roleTypes';
import { UseIsAuthorized } from '../../auth/useHasAuthorisedBy';
import DialogPrint from '../../print/components/DialogPrint';
import { exportXls } from '../services/exportXLS';
import PlotsUtils from '../services/PlotsUtils';
import PlotCreation from './plotCreation/PlotCreation';
import PlotCreationComponent from './plotCreationComponents/PlotCreationContainer';
import PlotDialogRenewConfirmation from './PlotDialogRenewConfirmation';
import PlotImportModal from './plotImportModal/PlotImportModal';

const options = ['Imprimer la selection', 'Reconduire la selection', 'Exporter la selection'];

type Props = {
  selectedPlots: { [key: string]: boolean };
  setSelectedPlots: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  plots: PlotData[];
};

export default function SplitButton({ selectedPlots, setSelectedPlots, plots }: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [openModalCreation, setOpenModalCreation] = useState<boolean>(false);
  const [openModalImport, setOpenModalImport] = useState<boolean>(false);
  const [openPrintModal, setOPenPrintModal] = useState<boolean>(false);
  const hasAuthorisationToPrint = UseIsAuthorized([
    roleTypes.ADMIN,
    roleTypes.EDITOR,
    roleTypes.VIEWER,
  ]);

  const hasAuthorisationToCreate = UseIsAuthorized([roleTypes.ADMIN, roleTypes.EDITOR]);

  const hasAuthorisationToRenew = UseIsAuthorized([roleTypes.ADMIN, roleTypes.EDITOR]);
  const [openDialogRenewConfirmation, setOpenDialogRenewConfirmation] = useState<boolean>(false);

  const countSelectedPlots = PlotsUtils.countSelectedPlots(selectedPlots);

  const isDisabled = () => {
    if (Object.values(selectedPlots).length === 0) {
      return true;
    }
    switch (selectedIndex) {
      case 0: // Add restriction for print
        return !hasAuthorisationToPrint;
      case 1: // Add rectriction to renew plot
        return !hasAuthorisationToRenew;
      default:
        return false;
    }
  };

  const handleCloseRenewConfirmationDialog = () => {
    setOpenDialogRenewConfirmation(false);
    setSelectedPlots({});
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const getSelectedPlots = (): PlotData[] => {
    const plotsSelected: PlotData[] = [];
    if (plots) {
      for (const plot of plots) {
        if (selectedPlots[plot.plot_id]) {
          plotsSelected.push(plot);
        }
      }
    }
    return plotsSelected;
  };

  const handleClick = () => {
    switch (selectedIndex) {
      case 0:
        setOPenPrintModal(!openPrintModal);
        break;
      case 1:
        setOpenDialogRenewConfirmation(!openDialogRenewConfirmation);
        break;
      case 2:
        exportXls(getSelectedPlots());
        break;
      default:
        return;
    }
  };

  return (
    <>
      <PlotCreation
        setOpenModalCreation={setOpenModalCreation}
        openModalCreation={openModalCreation}
      />

      {openDialogRenewConfirmation && (
        <PlotDialogRenewConfirmation
          selectedPlots={selectedPlots}
          onClose={handleCloseRenewConfirmationDialog}
          number={countSelectedPlots}
          open={openDialogRenewConfirmation}
        />
      )}
      {openModalImport && (
        <PlotImportModal setHandleClose={setOpenModalImport} isOpen={openModalImport} />
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Stack spacing={2} direction='row'>
          <PlotCreationComponent />
          <Button
            startIcon={<GetAppIcon />}
            onClick={() => setOpenModalImport(!openModalImport)}
            disabled={!hasAuthorisationToCreate}
          >
            Importer des parcelles
          </Button>
        </Stack>
        {openPrintModal && (
          <DialogPrint
            open={openPrintModal}
            onClose={() => setOPenPrintModal(false)}
            plots={getSelectedPlots()}
          />
        )}
        <Stack spacing={1} direction='row'>
          <ButtonGroup
            variant='contained'
            ref={anchorRef}
            aria-label='Button group with a nested menu'
          >
            <Button onClick={handleClick} disabled={isDisabled()}>
              {options[selectedIndex]}
            </Button>

            <Button
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select merge strategy'
              aria-haspopup='menu'
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>

      <Popper
        sx={{ zIndex: 1000000 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
