import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { PlotData } from 'library/models/plot';
import { ChangeEvent, useState } from 'react';
import { dataToPlots, PlotsError } from '../../services/dataToPlots';
import { readXlsToJson } from '../../services/readFile';
import { PropsStep } from './ImportStepOne';
import ImportValidation from './ImportValidation';

const ImportStepTwo = ({ nextStep }: PropsStep) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const [plotsToUpload, setPlotsToUpload] = useState<PlotData[] | null>(null);
  const [errorPlots, setErrorPlots] = useState<PlotsError[] | null>(null);
  const readFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const file = input.files?.[0];
    if (file) {
      try {
        const jsonData = await readXlsToJson(file);

        const result = dataToPlots(jsonData.Feuille1 as unknown as string[][]);

        console.log(result);
        setPlotsToUpload(result.plots);
        setErrorPlots(result.errors);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {!plotsToUpload ? (
        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload files
          <VisuallyHiddenInput type='file' onChange={(event) => readFile(event)} />
        </Button>
      ) : (
        <ImportValidation plots={plotsToUpload} errors={errorPlots ?? []} />
      )}
    </>
  );
};

export default ImportStepTwo;
