import {
  Autocomplete,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCenterConfiguration, setVintageConfiguration } from '../../configuration/store/slice';
import { useGetCentersQuery } from '../../customers/customerApi';
import { RootState } from '../../redux/store';
import { useGetVintagesInPlotsQuery } from '../store/plotApi';

type Props = {
  query: string;
  setQuery: (query: string) => void;
};

const PlotQueryCreator = ({ query, setQuery }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const { data: vintages } = useGetVintagesInPlotsQuery();
  const { data: centers, isLoading: isLoadingCenters } = useGetCentersQuery();
  const { t } = useTranslation();
  const [isActive, setIsactive] = useState<boolean>(true);
  const [isInactive, setIsInactive] = useState<boolean>(false);

  // TODO: Change with the hook
  const { vintage, center } = useSelector((state: RootState) => state.configurationSlice);
  const setCentre = useCallback((centre: string) => {
    dispatch(setCenterConfiguration(centre));
  }, []);

  const setVintage = useCallback((vintage: string) => {
    dispatch(setVintageConfiguration(vintage));
  }, []);

  const generateQuery = (): string => {
    let generatedQuery = '?';
    if (isActive) {
      generatedQuery += 'active=true';
    }
    if (isInactive) {
      isActive ? (generatedQuery += '&inactive=true') : (generatedQuery += 'inactive=true');
    }
    generatedQuery += '&vintages=' + vintage;
    generatedQuery += '&centers=' + center;

    return generatedQuery;
  };

  useEffect(() => {
    if (!isLoadingCenters && centers && centers?.length >= 0) {
      setCentre(centers[0].icv_center);
    }
  }, [centers, isLoadingCenters, setCentre]);

  useEffect(() => {
    setQuery(generateQuery());
  }, [isActive, isInactive, vintage, center, setQuery, query, isLoadingCenters]);

  return (
    <Container>
      <FormGroup>
        <Stack spacing={2} mb={2} direction={'row'} alignItems={'stretch'}>
          <Stack spacing={2} direction={'row'} flex={'wrap'} alignItems={'center'}>
            {vintages && (
              <Autocomplete
                id='tags-outlined'
                options={vintages ?? []}
                disableClearable
                getOptionLabel={(option) => option.toString()}
                filterSelectedOptions
                sx={{ width: 300 }}
                onChange={(event, value) => setVintage(value.toString())}
                value={Number(vintage)}
                defaultValue={Number(vintage)}
                renderInput={(params) => <TextField {...params} label={t('vintage')} />}
              />
            )}
            {centers && center && (
              <Autocomplete
                id='tags-outlined'
                options={centers ? centers.map((center) => center.icv_center) : []}
                disableClearable
                getOptionLabel={(option) => option}
                filterSelectedOptions
                sx={{ width: 300 }}
                onChange={(event, value) => {
                  value && setCentre(value);
                }}
                value={center}
                renderInput={(params) => <TextField {...params} label={t('center')} />}
              />
            )}
          </Stack>

          <Stack direction={'row'} spacing={2} mt={2}>
            {isActive !== null && (
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={isActive}
                      onChange={() => {
                        setIsactive(!isActive);
                      }}
                    />
                  }
                  label={t('active')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isInactive}
                      onChange={() => setIsInactive(!isInactive)}
                      size='small'
                    />
                  }
                  label={t('inactive')}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </FormGroup>
    </Container>
  );
};

export default PlotQueryCreator;
