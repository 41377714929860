import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type { FeatureCollection } from '@turf/turf';
import { CustomerData } from 'library/models/customer';
import { FarmData } from 'library/models/farm';
import { PlotDataCreation } from 'library/models/plot';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MatGeocoder from 'react-mui-mapbox-geocoder';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetCentersQuery,
  useGetCustomersByIcvCenterQuery,
} from '../../../customers/customerApi';
import FarmDialog from '../../../farms/components/FarmDialog';
import { useGetFarmsQuery } from '../../../farms/store/famsApi';
import { setName } from '../../../farms/store/slice';
import { useGetGrapeVarietiesQuery } from '../../../grapeVariety/store/grapeVarietyApi';
import { useCheckExistQuery } from '../../../plots/store/plotApi';
import { RootState } from '../../../redux/store';
import { utils } from '../../utils/utils';
import DisplayLastPlots from '../DisplayLastPlots';

type Props = {
  setStep?: (step: number) => void;
  onPlotPayloadChange: (plot: PlotDataCreation) => void;
  setCenter: (center: string) => void;
  center: string;
  setIsValid: (data: any) => void;
  plotPayload: PlotDataCreation;
};

const PlotCreationStepOne = ({
  onPlotPayloadChange,
  setIsValid,
  plotPayload,
  center,
  setCenter,
}: Props): React.ReactElement => {
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';
  const { t } = useTranslation();
  const { data: grapeVariety } = useGetGrapeVarietiesQuery();
  const { data: farms } = useGetFarmsQuery();
  const { data: centers } = useGetCentersQuery();
  const { data: customers } = useGetCustomersByIcvCenterQuery(center);
  const { name } = useSelector((state: RootState) => state.createFarmSlice);
  const [customer, setCustomer] = React.useState<CustomerData | null>(null);
  const [skip, setSkip] = React.useState<boolean>(true);
  const [isSelected, setIsSelected] = React.useState<boolean>(false);
  const [maxLengthFilter, setMaxLengthFilter] = React.useState<number>(0);

  const dispatch = useDispatch();

  const { data: isChecked } = useCheckExistQuery(
    {
      vintage: plotPayload.vintage.toString(),
      internal_plot_code: plotPayload.internal_plot_code ?? '',
      external_plot_code: plotPayload.external_plot_code,
      customer_id: customer?.solution_code ?? '',
    },
    {
      skip: skip,
    }
  );

  useEffect(() => {
    setIsValid(isChecked);
  }, [isChecked]);

  const returnYearsOptions = utils.returnYearsOptions();

  const geocoderApiOptions = {
    country: 'fr',
    types: 'place',
  };
  // TODO: find type
  const setAdressInformation = async (result: MapboxGeocoder.Result) => {
    result.text;
    const longitude = result.geometry.coordinates[0];
    const latitude = result.geometry.coordinates[1];
    const mapboxReverseGeocodingResult = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&access_token=${MAPBOX_TOKEN}`
    );
    const mapboxReverseGeocodingResultData: FeatureCollection =
      await mapboxReverseGeocodingResult.json();
    if (mapboxReverseGeocodingResultData.features.length > 0) {
      const feature = mapboxReverseGeocodingResultData.features[0] as MapboxGeocoder.Result;
      const zipcode = feature.context.find((context) => context.id.includes('postcode'))?.text;
      const country = feature.context
        .find((context) => context.id.includes('country'))
        ?.short_code?.toUpperCase();

      onPlotPayloadChange({
        ...plotPayload,
        municipality: result.text,
        zip_code: zipcode,
        country: country,
      });
    }
  };

  const addFarmButton = () => {
    return (
      <Button onClick={() => setOpen(true)} variant='outlined' startIcon={<AddCircleOutlineIcon />}>
        {t('addFarm')}
      </Button>
    );
  };

  useEffect(() => {
    if (
      plotPayload.vintage &&
      plotPayload.external_plot_code &&
      plotPayload.internal_plot_code &&
      customer?.client_id &&
      !isSelected
    ) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [
    plotPayload.external_plot_code,
    plotPayload.internal_plot_code,
    plotPayload.vintage,
    customer,
    isSelected,
  ]);
  const [open, setOpen] = React.useState(false);

  const handleClose = (farm: FarmData | undefined, cancel: boolean) => {
    if (cancel) {
      setOpen(false);
    }
    if (farm) {
      onPlotPayloadChange({ ...plotPayload, farm_id: farm.farm_id ?? '' });
    }
    setOpen(false);
  };
  return (
    <>
      {open && (
        <FarmDialog
          onCloseFromPlotCreation={(farm, cancel) => handleClose(farm, cancel ?? false)}
          isOpen={open}
          centerProps={centers?.find((c) => c.icv_center === center)}
          customerId={plotPayload?.solution_code ?? ''}
        />
      )}
      <Container>
        <Stack direction='row' spacing={4} flex={'wrap'} justifyContent={'space-between'}>
          <FormControl sx={{ m: 1 }}>
            <Autocomplete
              disablePortal
              onChange={(e, value) => {
                onPlotPayloadChange({ ...plotPayload, solution_code: '' });
                value ? setCenter(value.icv_center) : null;
              }}
              id='auto-complete-centre'
              options={centers ?? []}
              getOptionLabel={(option) => option.icv_center}
              value={centers?.find((c) => c.icv_center === center) ?? null}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                return options.filter((option) =>
                  option.icv_center.toLowerCase().includes(inputValue)
                );
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={t('center') + '*'} />}
            />
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <Autocomplete
              disabled={!center}
              disablePortal
              value={customers?.find((c) => c.solution_code === plotPayload?.solution_code) ?? null}
              id='auto-complete-clients'
              options={customers?.filter((customer) => customer.icv_center === center) ?? []}
              getOptionLabel={(option) =>
                option.solution_code +
                ' - ' +
                option.customer_name +
                ' - ' +
                option.solution_site_name
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.customer_id}>
                    {option.solution_code +
                      ' - ' +
                      option.customer_name +
                      ' - ' +
                      option.solution_site_name}
                  </li>
                );
              }}
              sx={{ width: 300 }}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    (option.active && option.customer_name.toLowerCase().includes(inputValue)) ||
                    option.solution_code.toLowerCase().includes(inputValue) ||
                    (option.solution_site_name &&
                      option.solution_site_name.toLowerCase().includes(inputValue))
                );
              }}
              onChange={(e, value) => {
                setCustomer(value);
                onPlotPayloadChange({ ...plotPayload, solution_code: value?.solution_code ?? '' });
              }}
              renderInput={(params) => <TextField {...params} label={t('customers') + '*'} />}
            />
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <Autocomplete
              disablePortal
              disabled={!plotPayload?.solution_code}
              id='auto-complete-exploitation'
              options={
                farms ? farms.filter((farm) => farm.customer_id === customer?.solution_code) : []
              }
              noOptionsText={addFarmButton()}
              renderOption={(props, option, index) => {
                return (
                  <>
                    <li {...props} key={option.farm_id}>
                      {option.farm_name}
                    </li>
                    {index.index + 1 === maxLengthFilter && (
                      <li style={{ marginBottom: '10px' }}>{addFarmButton()}</li>
                    )}
                  </>
                );
              }}
              value={farms?.find((f) => f.farm_id === plotPayload?.farm_id) ?? null}
              getOptionLabel={(option) => option.farm_name ?? ''}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue;
                dispatch(setName(inputValue));
                const optionsFiltered = options.filter((option) =>
                  option.farm_name.toLowerCase().includes(name ?? inputValue.toLowerCase())
                );
                setMaxLengthFilter(optionsFiltered.length);
                return optionsFiltered;
              }}
              sx={{ width: 300 }}
              onChange={(e, value) =>
                onPlotPayloadChange({ ...plotPayload, farm_id: value?.farm_id ?? '' })
              }
              renderInput={(params) => <TextField {...params} label={t('farms') + '*'} />}
            />
          </FormControl>
        </Stack>

        <Stack direction='row' spacing={4} flex={'wrap'} justifyContent={'space-between'}>
          <FormControl sx={{ m: 1 }}>
            <TextField
              sx={{ width: 300 }}
              error={isChecked?.internal_code}
              label={t('solutionCode') + '*'}
              onFocus={() => setIsSelected(true)}
              onBlur={() => setIsSelected(false)}
              value={plotPayload?.internal_plot_code ?? ''}
              onChange={(e) =>
                onPlotPayloadChange({ ...plotPayload, internal_plot_code: e.target.value })
              }
            />
            {isChecked?.internal_code && (
              <Typography mt={1} color='red' align='right' fontSize={9}>
                {t('code.already.used')}
              </Typography>
            )}
            {customer?.solution_code && plotPayload?.vintage && (
              <DisplayLastPlots
                value='internal_plot_code'
                customerId={customer?.solution_code}
                vintage={plotPayload.vintage}
              />
            )}
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <TextField
              sx={{ width: 300 }}
              error={isChecked?.external_code}
              label={t('externalCode') + '*'}
              onFocus={() => setIsSelected(true)}
              onBlur={() => setIsSelected(false)}
              value={plotPayload?.external_plot_code ?? ''}
              onChange={(e) =>
                onPlotPayloadChange({ ...plotPayload, external_plot_code: e.target.value })
              }
            />
            {isChecked?.external_code && (
              <Typography mt={1} color='red' align='right' fontSize={9}>
                {t('code.already.used')}
              </Typography>
            )}
            {customer?.solution_code && plotPayload?.vintage && (
              <DisplayLastPlots
                value='external_plot_code'
                customerId={customer?.solution_code}
                vintage={plotPayload.vintage}
              />
            )}
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <TextField
              sx={{ width: 300 }}
              label={t('plotName')}
              value={plotPayload?.external_plot_name ?? ''}
              onChange={(e) =>
                onPlotPayloadChange({ ...plotPayload, external_plot_name: e.target.value })
              }
            />
          </FormControl>
        </Stack>
        {/* <Stack direction={'row'} spacing={8} width={'100%'}>
            {/* <FormControl sx={{ m: 1, width: '35%' }}>
              <TextField
                label={t('surface')}
                value={plotPayload.surface_ha}
                onChange={(e) =>
                  onPlotPayloadChange({
                    ...plotPayload,
                    surface_ha: parseFloat(e.target.value),
                  })
                }
                name='numberformat'
                id='formatted-numberformat-input'
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
              />
            </FormControl> 
          </Stack> */}
        <Stack
          direction={'row'}
          spacing={8}
          width={'100%'}
          flex={'wrap'}
          justifyContent={'space-between'}
        >
          <FormControl sx={{ m: 1 }}>
            <Autocomplete
              disablePortal
              sx={{ width: 300 }}
              id='auto-complete-grapeVariety'
              options={grapeVariety ?? []}
              value={grapeVariety?.find((c) => c.cepage_code === plotPayload?.cepage_code) ?? null}
              getOptionLabel={(option) => option.cepage_complete_name}
              fullWidth
              onChange={(e, value) =>
                onPlotPayloadChange({ ...plotPayload, cepage_code: value?.cepage_code ?? '' })
              }
              renderInput={(params) => <TextField {...params} label={t('cepage') + '*'} />}
            />
          </FormControl>

          {/* <FormControl sx={{ m: 1, width: '35%' }}>
              <Autocomplete
                disablePortal
                sx={{ minWidth: '180' }}
                id='auto-complete-sectors'
                options={sectors ?? []}
                getOptionLabel={(option) => option.sector_name}
                value={sectors?.find((s) => s.sector_code === plotPayload?.sector_code) ?? null}
                fullWidth
                onChange={(e, value) =>
                  onPlotPayloadChange({ ...plotPayload, sector_code: value?.sector_code ?? '' })
                }
                renderInput={(params) => <TextField {...params} label={t('sector')} />}
              />
            </FormControl> */}
          <FormControl fullWidth={false} sx={{ m: 1, width: 300 }}>
            <MatGeocoder
              inputPlaceholder={t('town') ?? ''}
              accessToken={MAPBOX_TOKEN}
              onSelect={setAdressInformation}
              showLoader={true}
              {...geocoderApiOptions}
            />
            <Typography variant='body1' mt={2} color='text.secondary' align='right'>
              {plotPayload?.municipality &&
                `${plotPayload?.zip_code ?? ''} ${plotPayload?.municipality} ${
                  plotPayload?.country
                }`}
            </Typography>
          </FormControl>
          {returnYearsOptions.length > 0 && (
            <FormControl sx={{ m: 1 }}>
              <InputLabel sx={{ width: 300 }} id='millesim'>
                {t('millesime.create') + '*'}
              </InputLabel>
              <Select
                sx={{ width: 300 }}
                value={plotPayload?.vintage ?? new Date().getFullYear()}
                error={!!plotPayload.vintage && !returnYearsOptions}
                onChange={(e) =>
                  onPlotPayloadChange({ ...plotPayload, vintage: Number(e.target.value) })
                }
              >
                {returnYearsOptions.map((year) => {
                  return (
                    <MenuItem value={year} key={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Stack>
        <Stack direction={'row'} spacing={8} width={'100%'} flex={'wrap'}></Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={plotPayload?.active ?? false}
              onChange={(e) => onPlotPayloadChange({ ...plotPayload, active: e.target.checked })}
              value={plotPayload?.active ?? false}
            />
          }
          label={t('active')}
        />
      </Container>
    </>
  );
};
export default PlotCreationStepOne;
