import { PlotData } from 'library/models/plot';
import { utils, writeFile } from 'xlsx';

export function exportXls(plots: PlotData[]) {
  const worksheet = utils.json_to_sheet(plots);

  // Créer un classeur et y ajouter la feuille de calcul
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Feuille1');

  // Écrire le fichier Excel
  writeFile(workbook, `test.xlsx`);
}
