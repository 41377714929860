import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import CustomersList from '../customers/components/CustomersList';
import FarmList from '../farms/components/FarmList';
import GrapeVarietiesList from '../grapeVariety/components/GrapeVarietiesList';
import PlotsPage from '../plots/pages/Plots';
import { LoginPage, Page404 } from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'customers',
          element: <CustomersList />,
        },
        {
          path: 'farms',
          element: <FarmList />,
        },
        {
          path: 'cepages',
          element: <GrapeVarietiesList />,
        },
        {
          path: 'plots',
          element: <PlotsPage />, // [
          // { element: <CreationPage />, index: true },
          // { path: 'creation', element: <CreationPage /> },
          // { path: 'renewal', element: <RenewPage /> },
          // { path: 'print', element: <PrintPage /> },
          //  ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}
