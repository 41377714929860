import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FeatureCollection } from '@turf/turf';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MatGeocoder from 'react-mui-mapbox-geocoder';
import { useGetGrapeVarietiesQuery } from '../../../../grapeVariety/store/grapeVarietyApi';
import { useCheckExistQuery } from '../../../store/plotApi';
import { utils } from '../../../utils/utils';
import DisplayLastPlots from '../../DisplayLastPlots';
import { usePlotContext } from '../../plotCreation/hooks/usePlotCreation';

const geocoderApiOptions = {
  country: 'fr',
  types: 'place',
};

const FirstTabBody = () => {
  const { t } = useTranslation();
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';
  const { setPlotPayload, plotPayload, customer } = usePlotContext();
  const returnYearsOptions = utils.returnYearsOptions();
  const { data: grapeVariety } = useGetGrapeVarietiesQuery();
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(true);

  const setAdressInformation = async (result: MapboxGeocoder.Result) => {
    result.text;
    const longitude = result.geometry.coordinates[0];
    const latitude = result.geometry.coordinates[1];
    const mapboxReverseGeocodingResult = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&access_token=${MAPBOX_TOKEN}`
    );
    const mapboxReverseGeocodingResultData: FeatureCollection =
      await mapboxReverseGeocodingResult.json();
    if (mapboxReverseGeocodingResultData.features.length > 0) {
      const feature = mapboxReverseGeocodingResultData.features[0] as MapboxGeocoder.Result;
      const zipcode = feature.context.find((context) => context.id.includes('postcode'))?.text;
      const country = feature.context
        .find((context) => context.id.includes('country'))
        ?.short_code?.toUpperCase();

      setPlotPayload({
        ...plotPayload,
        municipality: result.text,
        zip_code: zipcode,
        country: country,
      });
    }
  };

  useEffect(() => {
    if (
      plotPayload.vintage &&
      plotPayload.external_plot_code &&
      plotPayload.internal_plot_code &&
      customer?.client_id &&
      !isSelected
    ) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [
    plotPayload.external_plot_code,
    plotPayload.internal_plot_code,
    plotPayload.vintage,
    customer,
    isSelected,
  ]);

  const { data: isChecked } = useCheckExistQuery(
    {
      vintage: plotPayload.vintage.toString(),
      internal_plot_code: plotPayload.internal_plot_code ?? '',
      external_plot_code: plotPayload.external_plot_code,
      customer_id: customer?.solution_code ?? '',
    },
    {
      skip: skip,
    }
  );

  return (
    <>
      <Stack direction='row' spacing={4} flex={'wrap'} justifyContent={'space-between'}>
        <FormControl sx={{ m: 1 }}>
          <TextField
            sx={{ width: 300 }}
            error={isChecked?.internal_code}
            label={t('solutionCode') + '*'}
            onFocus={() => setIsSelected(true)}
            onBlur={() => setIsSelected(false)}
            value={plotPayload?.internal_plot_code ?? ''}
            onChange={(e) => setPlotPayload({ ...plotPayload, internal_plot_code: e.target.value })}
          />
          {isChecked?.internal_code && (
            <Typography mt={1} color='red' align='right' fontSize={9}>
              {t('code.already.used')}
            </Typography>
          )}
          {customer?.solution_code && plotPayload?.vintage && (
            <DisplayLastPlots
              value='internal_plot_code'
              customerId={customer?.solution_code}
              vintage={plotPayload.vintage}
            />
          )}
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <TextField
            sx={{ width: 300 }}
            error={isChecked?.external_code}
            label={t('externalCode') + '*'}
            onFocus={() => setIsSelected(true)}
            onBlur={() => setIsSelected(false)}
            value={plotPayload?.external_plot_code ?? ''}
            onChange={(e) => setPlotPayload({ ...plotPayload, external_plot_code: e.target.value })}
          />
          {isChecked?.external_code && (
            <Typography mt={1} color='red' align='right' fontSize={9}>
              {t('code.already.used')}
            </Typography>
          )}
          {customer?.solution_code && plotPayload?.vintage && (
            <DisplayLastPlots
              value='external_plot_code'
              customerId={customer?.solution_code}
              vintage={plotPayload.vintage}
            />
          )}
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <TextField
            sx={{ width: 300 }}
            label={t('plotName')}
            value={plotPayload?.external_plot_name ?? ''}
            onChange={(e) => setPlotPayload({ ...plotPayload, external_plot_name: e.target.value })}
          />
        </FormControl>
      </Stack>
      <Stack
        direction={'row'}
        spacing={8}
        width={'100%'}
        flex={'wrap'}
        justifyContent={'space-between'}
      >
        <FormControl sx={{ m: 1 }}>
          <Autocomplete
            disablePortal
            sx={{ width: 300 }}
            id='auto-complete-grapeVariety'
            options={grapeVariety ?? []}
            value={grapeVariety?.find((c) => c.cepage_code === plotPayload?.cepage_code) ?? null}
            getOptionLabel={(option) => option.cepage_complete_name}
            fullWidth
            onChange={(e, value) =>
              setPlotPayload({ ...plotPayload, cepage_code: value?.cepage_code ?? '' })
            }
            renderInput={(params) => <TextField {...params} label={t('cepage') + '*'} />}
          />
        </FormControl>
        <FormControl fullWidth={false} sx={{ m: 1, width: 300 }}>
          <MatGeocoder
            inputPlaceholder={t('town') ?? ''}
            accessToken={MAPBOX_TOKEN}
            onSelect={setAdressInformation}
            showLoader={true}
            {...geocoderApiOptions}
          />
          <Typography variant='body1' mt={2} color='text.secondary' align='right'>
            {plotPayload?.municipality &&
              `${plotPayload?.zip_code ?? ''} ${plotPayload?.municipality} ${plotPayload?.country}`}
          </Typography>
        </FormControl>
        {returnYearsOptions.length > 0 && (
          <FormControl sx={{ m: 1 }}>
            <InputLabel sx={{ width: 300 }} id='millesim'>
              {t('millesime.create') + '*'}
            </InputLabel>
            <Select
              sx={{ width: 300 }}
              value={plotPayload?.vintage ?? new Date().getFullYear()}
              error={!!plotPayload.vintage && !returnYearsOptions}
              onChange={(e) => setPlotPayload({ ...plotPayload, vintage: Number(e.target.value) })}
            >
              {returnYearsOptions.map((year) => {
                return (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Stack>
      <Stack direction={'row'} spacing={8} width={'100%'} flex={'wrap'}></Stack>
      <FormControlLabel
        control={
          <Checkbox
            checked={plotPayload?.active ?? false}
            onChange={(e) => setPlotPayload({ ...plotPayload, active: e.target.checked })}
            value={plotPayload?.active ?? false}
          />
        }
        label={t('active')}
      />
    </>
  );
};

export default FirstTabBody;
