import { utils, writeFile } from 'xlsx';

type Model = {
  title: string;
};

export function modelXLS() {
  const model: Model[] = [{ title: 'test' }];

  const worksheet = utils.json_to_sheet(model);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Feuille1');

  writeFile(workbook, `model.xlsx`);
}
