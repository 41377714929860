import { PlotProviderComponent } from './plotCreation/hooks/usePlotCreation';
import PlotUpdateModalContainer from './plotUpdateComponents/PlotUpdateModalContainer';

type Props = {
  plotId: string;
};

const UpdatePlot = ({ plotId }: Props) => {
  return (
    <PlotProviderComponent>
      <PlotUpdateModalContainer plotId={plotId} />
    </PlotProviderComponent>
  );
};

export default UpdatePlot;
