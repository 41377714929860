import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  name: string | null;
}

const initialState: InitialState = {
  name: null,
};

export const createFarmSlice = createSlice({
  name: 'createFarm',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    deleteName: (state) => {
      state.name = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setName, deleteName } = createFarmSlice.actions;

export default createFarmSlice.reducer;
