// routes
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import MapIcon from '@mui/icons-material/Map';
import SpokeIcon from '@mui/icons-material/Spoke';
import { roleTypes } from '../../../auth/roleTypes';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general v4.3.0',
    items: [
      {
        title: 'Les clients',
        path: PATH_DASHBOARD.customers,
        icon: <AccessibilityNewIcon />,
        roles: [roleTypes.ADMIN],
      },
      {
        title: 'Les exploitations',
        path: PATH_DASHBOARD.farms,
        icon: <AgricultureIcon />,
        roles: [roleTypes.ADMIN, roleTypes.EDITOR, roleTypes.VIEWER],
      },
      {
        icon: <MapIcon />,
        title: 'Les parcelles',
        path: PATH_DASHBOARD.plots,
        roles: [roleTypes.ADMIN, roleTypes.EDITOR, roleTypes.VIEWER],
      },
      {
        title: 'Les cépages',
        path: PATH_DASHBOARD.grapeVarieties,
        icon: <SpokeIcon />,
        roles: [roleTypes.ADMIN],
      },
    ],
  },
];

export default navConfig;
