import { CustomerData } from 'library/models/customer';
import { PlotDataCreation } from 'library/models/plot';
import { AdditionAttributeCreation, CentroidData } from 'library/models/plotCreation.request';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  useCheckExistQuery,
  useCreatePlotMutation,
  useUpdatePlotMutation,
} from '../../../store/plotApi';
import { Attributes } from '../../plotCreationComponents/PlotCreationAttribut';
import { IsChecked } from '../../plotUpdate/PlotUpdateStepOne';
import { PlotProvider } from './usePlotCreation';

const emptyPlotPayload: PlotDataCreation = {
  external_plot_code: '',
  external_plot_name: '',
  internal_plot_code: '',
  surface_ha: 0,
  vintage: new Date().getFullYear(),
  solution_code: '',
  farm_id: '',
  active: true,
  cepage_code: '',
};

export const usePlotProvider = (): PlotProvider => {
  //Manage Modal
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const [isSnackbarError, setIsSnackbarError] = useState<boolean>(false);
  const [isSnackBarSuccess, setSnackBarSuccess] = useState<boolean>(false);
  const [selectedPlotGroup, setSelectedPlotGroup] = useState<{
    [keyof: string]: boolean | undefined;
  }>({});
  const { center } = useSelector((state: RootState) => state.configurationSlice);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<IsChecked | undefined>(undefined);
  const [skip, setSkip] = useState<boolean>(true);
  const [selectedAttributes, setSelectedAttribute] = useState<Attributes | null>(null);
  const [customer, setCustomer] = useState<CustomerData | null>(null);

  const [updatePlot, { isError: isUpdateError, isSuccess: isUpdateSuccess, isLoading: load }] =
    useUpdatePlotMutation();

  const [plotPayload, setPlotPayload] = useState<PlotDataCreation>(emptyPlotPayload);

  const { data: isChecked } = useCheckExistQuery(
    {
      vintage: plotPayload.vintage.toString(),
      internal_plot_code: plotPayload.internal_plot_code ?? '',
      external_plot_code: plotPayload.external_plot_code,
      customer_id: customer?.solution_code ?? '',
    },
    {
      skip: skip,
    }
  );

  useEffect(() => {
    setIsValid(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (
      plotPayload.vintage &&
      plotPayload.external_plot_code &&
      plotPayload.internal_plot_code &&
      customer?.client_id &&
      !isSelected
    ) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [
    plotPayload.external_plot_code,
    plotPayload.internal_plot_code,
    plotPayload.vintage,
    customer,
    isSelected,
  ]);

  //TODO: setCentroid is not used for the moment.
  const [centroid, setCentroid] = useState<CentroidData>({
    longitude: 0,
    latitude: 0,
    type: 'point',
  });

  const [createPlot, { isLoading, isError, isSuccess }] = useCreatePlotMutation();

  const [selectedService, setSelectedService] = useState<string[]>([]);

  const handleClose = () => {
    setOpen(false);
    setPlotPayload(emptyPlotPayload);
  };

  useEffect(() => {
    if (isError) {
      setIsSnackbarError(true);
    }
    if (isSuccess) {
      setSnackBarSuccess(true);
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const isDisabled = () => {
    return true;
  };

  const attributesToPost = useCallback(() => {
    const attributes = [];
    console.log('selectedAttributes in attributesToPost', selectedAttributes);
    for (const key in selectedAttributes) {
      const value = selectedAttributes[key];
      const attribute: AdditionAttributeCreation = { key, value };
      attributes.push(attribute);
    }
    return attributes;
  }, [selectedAttributes]);

  const groupsToPost = Object.keys(selectedPlotGroup).filter(
    (key) => selectedPlotGroup[key] === true
  );

  const validate = async () => {
    if (plotPayload) {
      await createPlot({
        plot: plotPayload,
        centroid: centroid,
        groups: groupsToPost,
        services: selectedService,
        additionalAttribute: attributesToPost(),
      });
    }
  };

  const onPostPlotDatabase = async () => {
    if (selectedPlotGroup) {
      // TODO to externalise with updatePlot
      if (plotPayload && centroid) {
        // map payload to PlotDataCreation
        const payload: PlotDataCreation = {
          external_plot_code: plotPayload.external_plot_code,
          internal_plot_code: plotPayload.internal_plot_code,
          previous_plot_id: plotPayload.previous_plot_id,
          solution_code: plotPayload.solution_code,
          surface_ha: plotPayload.surface_ha,
          vintage: plotPayload.vintage,
          farm_id: plotPayload.farm_id,
          active: plotPayload.active,
          cepage_code: plotPayload.cepage_code,
          external_plot_name: plotPayload.external_plot_name,
        };
        await createPlot({
          plot: payload,
          centroid: centroid,
          groups: groupsToPost,
          services: selectedService,
          additionalAttribute: attributesToPost(),
        });
      }
    }
  };

  const handleUpdatePlot = () => {
    updatePlot({
      plot: plotPayload,
      groups: groupsToPost,
      centroid: centroid ?? undefined,
      services: selectedService,
      additionalAttribute: attributesToPost(),
    });
  };

  return {
    open,
    handleOpen,
    handleClose,
    isValid,
    setIsValid,
    selectedAttributes,
    setSelectedAttribute,
    center,
    plotPayload,
    setPlotPayload,
    isDisabled,
    selectedService,
    setSelectedService,
    validate,
    skip,
    setCustomer,
    customer,
    isSnackbarError,
    setIsSnackbarError,
    isSnackBarSuccess,
    setSnackBarSuccess,
    centroid,
    setCentroid,
    setSelectedPlotGroup,
    selectedPlotGroup,
    onPostPlotDatabase,
    handleUpdatePlot,
    isUpdateSuccess,
    isChecked,
  };
};
