import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete, Button, FormControl, Stack, TextField } from '@mui/material';
import { FarmData } from 'library/models/farm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCenterConfiguration } from '../../../../configuration/store/slice';
import {
  useGetCentersQuery,
  useGetCustomersByIcvCenterQuery,
} from '../../../../customers/customerApi';
import FarmDialog from '../../../../farms/components/FarmDialog';
import { useGetFarmsQuery } from '../../../../farms/store/famsApi';
import { setName } from '../../../../farms/store/slice';
import { RootState } from '../../../../redux/store';
import { usePlotContext } from '../../plotCreation/hooks/usePlotCreation';

const FirstTabHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setPlotPayload, plotPayload, setCustomer, customer } = usePlotContext();
  const setCenter = (center: string) => {
    dispatch(setCenterConfiguration(center));
  };

  const { center } = useSelector((state: RootState) => state.configurationSlice);
  const [open, setOpen] = React.useState(false);

  const { data: farms } = useGetFarmsQuery();
  const { data: centers } = useGetCentersQuery();
  const { data: customers } = useGetCustomersByIcvCenterQuery(center);
  const { name } = useSelector((state: RootState) => state.createFarmSlice);

  const [maxLengthFilter, setMaxLengthFilter] = React.useState<number>(0);

  const addFarmButton = () => {
    return (
      <Button onClick={() => setOpen(true)} variant='outlined' startIcon={<AddCircleOutlineIcon />}>
        {t('addFarm')}
      </Button>
    );
  };

  const handleClose = (farm: FarmData | undefined, cancel: boolean) => {
    if (cancel) {
      setOpen(false);
    }
    if (farm) {
      setPlotPayload({ ...plotPayload, farm_id: farm.farm_id ?? '' });
    }
    setOpen(false);
  };

  return (
    <Stack direction='row' spacing={4} flex={'wrap'} justifyContent={'space-between'}>
      {open && (
        <FarmDialog
          onCloseFromPlotCreation={(farm, cancel) => handleClose(farm, cancel ?? false)}
          isOpen={open}
          centerProps={centers?.find((c) => c.icv_center === center)}
          customerId={plotPayload?.solution_code ?? ''}
        />
      )}
      <FormControl sx={{ m: 1 }}>
        <Autocomplete
          disablePortal
          onChange={(e, value) => {
            setPlotPayload({ ...plotPayload, solution_code: '' });
            value ? setCenter(value.icv_center) : null;
          }}
          id='auto-complete-centre'
          options={centers ?? []}
          getOptionLabel={(option) => option.icv_center}
          value={centers?.find((c) => c.icv_center === center) ?? null}
          filterOptions={(options, state) => {
            const inputValue = state.inputValue.toLowerCase();
            return options.filter((option) => option.icv_center.toLowerCase().includes(inputValue));
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label={t('center') + '*'} />}
        />
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        <Autocomplete
          disabled={!center}
          disablePortal
          value={customers?.find((c) => c.solution_code === plotPayload?.solution_code) ?? null}
          id='auto-complete-clients'
          options={customers?.filter((customer) => customer.icv_center === center) ?? []}
          getOptionLabel={(option) =>
            option.solution_code + ' - ' + option.customer_name + ' - ' + option.solution_site_name
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.customer_id}>
                {option.solution_code +
                  ' - ' +
                  option.customer_name +
                  ' - ' +
                  option.solution_site_name}
              </li>
            );
          }}
          sx={{ width: 300 }}
          filterOptions={(options, state) => {
            const inputValue = state.inputValue.toLowerCase();
            return options.filter(
              (option) =>
                (option.active && option.customer_name.toLowerCase().includes(inputValue)) ||
                option.solution_code.toLowerCase().includes(inputValue) ||
                (option.solution_site_name &&
                  option.solution_site_name.toLowerCase().includes(inputValue))
            );
          }}
          onChange={(e, value) => {
            setCustomer(value);
            setPlotPayload({ ...plotPayload, solution_code: value?.solution_code ?? '' });
          }}
          renderInput={(params) => <TextField {...params} label={t('customers') + '*'} />}
        />
      </FormControl>
      <FormControl sx={{ m: 1 }}>
        <Autocomplete
          disablePortal
          disabled={!plotPayload?.solution_code}
          id='auto-complete-exploitation'
          options={
            farms ? farms.filter((farm) => farm.customer_id === customer?.solution_code) : []
          }
          noOptionsText={addFarmButton()}
          renderOption={(props, option, index) => {
            return (
              <>
                <li {...props} key={option.farm_id}>
                  {option.farm_name}
                </li>
                {index.index + 1 === maxLengthFilter && (
                  <li style={{ marginBottom: '10px' }}>{addFarmButton()}</li>
                )}
              </>
            );
          }}
          value={farms?.find((f) => f.farm_id === plotPayload?.farm_id) ?? null}
          getOptionLabel={(option) => option.farm_name ?? ''}
          filterOptions={(options, state) => {
            const inputValue = state.inputValue;
            dispatch(setName(inputValue));
            const optionsFiltered = options.filter((option) =>
              option.farm_name.toLowerCase().includes(name ?? inputValue.toLowerCase())
            );
            setMaxLengthFilter(optionsFiltered.length);
            return optionsFiltered;
          }}
          sx={{ width: 300 }}
          onChange={(e, value) => setPlotPayload({ ...plotPayload, farm_id: value?.farm_id ?? '' })}
          renderInput={(params) => <TextField {...params} label={t('farms') + '*'} />}
        />
      </FormControl>
    </Stack>
  );
};

export default FirstTabHeader;
