import { read, utils, WorkBook } from 'xlsx';

export function readXlsToJson(fileItem: File): Promise<Record<string, any[][]>> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      // Événement lorsque la lecture du fichier est terminée
      reader.onload = (event: ProgressEvent<FileReader>) => {
        try {
          if (!event.target?.result) {
            return reject('Erreur : Fichier non lu.');
          }

          // Charger le contenu binaire dans xlsx
          const workbook: WorkBook = read(event.target.result, { type: 'binary' });

          // Objet pour stocker les données JSON des feuilles
          const result: Record<string, any[][]> = {};

          // Parcourir toutes les feuilles du classeur
          workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];

            // Convertir les données de la feuille en JSON
            const sheetData: any[][] = utils.sheet_to_json(worksheet, { header: 1 });

            // Ajouter les données JSON au résultat
            result[sheetName] = sheetData;
          });

          resolve(result);
        } catch (error) {
          reject(
            `Erreur lors de la conversion du fichier XLS en JSON : ${(error as Error).message}`
          );
        }
      };

      // Événement en cas d'erreur de lecture
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        reject(`Erreur de lecture du fichier : ${error.target?.error?.message || 'Inconnue'}`);
      };

      // Lire le fichier en tant que binaire
      reader.readAsBinaryString(fileItem);
    } catch (error) {
      reject(`Erreur générale : ${(error as Error).message}`);
    }
  });
}
