import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialState {
  center: string;
  site: string;
  vintage: string;
  client: string;
}

const initialState: InitialState = {
  center: '',
  site: '',
  client: '',
  vintage: `${new Date().getFullYear()}`,
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setVintageConfiguration: (state, action: PayloadAction<string>) => {
      state.vintage = action.payload;
    },
    setSiteConfiguration: (state, action: PayloadAction<string>) => {
      state.site = action.payload;
    },
    setCenterConfiguration: (state, action: PayloadAction<string>) => {
      state.center = action.payload;
    },
    setClientConfiguration: (state, action: PayloadAction<string>) => {
      state.client = action.payload;
    },
  },
});

export const {
  setSiteConfiguration,
  setCenterConfiguration,
  setClientConfiguration,
  setVintageConfiguration,
} = configurationSlice.actions;

export default configurationSlice.reducer;
