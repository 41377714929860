import { Box, Button, Container, Typography } from '@mui/material';
import { modelXLS } from '../../services/modelXLS';

export type PropsStep = {
  nextStep: () => void;
};

const ImportstepOne = ({ nextStep }: PropsStep) => {
  return (
    <Container>
      <Box>
        <Typography>
          {`Oenobox a un format de données bien spécifique. Le fichier Excel vous servira de modèle
          pour importer les données. Un tutoriel vidéo est disponible ici. Vous ne pouvez importer
          les données que d'un seul site client à la fois`}
        </Typography>
      </Box>
      <Button onClick={() => modelXLS()}>Télécharger le model</Button>
      <Button onClick={() => nextStep()}>Suivant</Button>
    </Container>
  );
};

export default ImportstepOne;
