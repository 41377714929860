import { PlotData } from 'library/models/plot';

export type PlotsError = { value: string; line: number };
export type DataToPlotsResult = {
  errors: PlotsError[];
  plots: PlotData[];
};

export function dataToPlots(data: string[][]): DataToPlotsResult {
  const plots: PlotData[] = [];
  const index = data[0];
  const errors: PlotsError[] = [];

  if (data.length) {
    for (let i = 1; i <= data.length - 1; i++) {
      const line = data[i];
      const plot = {};
      for (let j = 0; j < line.length; j++) {
        console.log(line[j]);
        if (line[j] === undefined) {
          const error = {
            value: index[j],
            line: i,
          };
          errors.push(error);
          break;
        } else {
          if (line[j]) {
            //@ts-ignore
            plot[index[j]] = line[j];
          }
        }
      }
      //@ts-ignore
      if (plot.plot_id) {
        plots.push(plot as PlotData);
      }
    }
  }

  return { plots, errors };
}
