import { Box } from '@mui/material';
import FirstTabHeader from './FirastTabHeader';
import FirstTabBody from './FirstTabBody';

const FirstTabContainer = () => {
  return (
    <Box>
      <FirstTabHeader />
      <FirstTabBody />
    </Box>
  );
};

export default FirstTabContainer;
