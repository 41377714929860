import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AdditionalAttribute } from 'library/plots/models/AdditionalAttribute.entity';
import { authPrepareHeaders } from '../../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../../config-global';

export const additionalAttributeAPi = createApi({
  reducerPath: 'additionalAttributeAPi',
  tagTypes: ['additionalAttribute'],
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders,
  }),
  endpoints: (builder) => ({
    getAdditionalAttribute: builder.query<AdditionalAttribute[], void>({
      query: () => 'additionalAttribute',
      providesTags: ['additionalAttribute'],
    }),
  }),
});

export const { useGetAdditionalAttributeQuery } = additionalAttributeAPi;
