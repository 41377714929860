import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useGetReferentielsQuery } from '../../../referentiel/store/referentielApi';
import { useGetAllActiveServiceQuery } from '../../../services/serviceApi';
import { usePlotContext } from '../plotCreation/hooks/usePlotCreation';

const PlotCreationServices = (): React.ReactElement => {
  const { data: services } = useGetAllActiveServiceQuery();
  const { data: plotGroups } = useGetReferentielsQuery();
  const { selectedPlotGroup, setSelectedPlotGroup, selectedService, setSelectedService } =
    usePlotContext();

  const handleChangeRef = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    const key = value[0];
    setSelectedPlotGroup({
      ...selectedPlotGroup,
      [key]: !selectedPlotGroup[key],
    });
  };

  const handleChangeService = (event: SelectChangeEvent<typeof selectedService>) => {
    const {
      target: { value },
    } = event;
    setSelectedService(typeof value === 'string' ? value.split(',') : value);
  };

  if (!services || !plotGroups) {
    return <></>;
  }

  return (
    <>
      <div>
        <Typography m={4} variant='h5'>
          Service
        </Typography>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id='demo-multiple-chip-label'>Services</InputLabel>
          <Select
            labelId='demo-multiple-chip-label'
            id='demo-multiple-chip'
            multiple
            value={selectedService}
            onChange={handleChangeService}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
          >
            {services &&
              services.map((service) => (
                <MenuItem key={service.service_code} value={service.service_code}>
                  {service.service_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', marginTop: 2, direction: 'row', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedService.map((value) => (
            <Chip
              key={value}
              label={services && services.find((s) => s.service_code === value)?.service_name}
            />
          ))}
        </Box>
      </div>
      <div>
        <Typography m={4} variant='h5'>
          Référentiel
        </Typography>

        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id='demo-multiple-chip-label'>Référentiel de la parcelle</InputLabel>
          <Select
            labelId='demo-multiple-chip-label'
            id='demo-multiple-chip'
            multiple
            value={[]}
            onChange={handleChangeRef}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
          >
            {plotGroups &&
              plotGroups.map((plotGroups) => (
                <MenuItem key={plotGroups.plot_group_code} value={plotGroups.plot_group_code}>
                  {plotGroups.plot_group_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', direction: 'row', marginTop: 2, gap: 0.5 }}>
          {Object.keys(selectedPlotGroup).map((value) => (
            <Chip
              key={value}
              label={
                plotGroups &&
                plotGroups.find((group) => group.plot_group_code === value)?.plot_group_name
              }
            />
          ))}
        </Box>
      </div>
    </>
  );
};

export default PlotCreationServices;
