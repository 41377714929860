import { Autocomplete, Box, FormControl, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { CentroidData } from 'library/models/plotCreation.request';
import NumericFormatCustom from '../../../components/text-field/TextField';
import { useGetSectorsQuery } from '../../../sector/store/apiSector';
import { usePlotContext } from '../plotCreation/hooks/usePlotCreation';

const PlotCreationGeoLocalization = () => {
  const { setPlotPayload, plotPayload, centroid, setCentroid } = usePlotContext();
  const { data: sectors } = useGetSectorsQuery();

  return (
    <Box>
      <Stack direction={'row'} spacing={4} width={'100%'}>
        <FormControl sx={{ width: '35%' }}>
          <TextField
            label={t('surface')}
            value={plotPayload.surface_ha}
            onChange={(e) =>
              setPlotPayload({
                ...plotPayload,
                surface_ha: parseFloat(e.target.value),
              })
            }
            name='numberformat'
            id='formatted-numberformat-input'
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </FormControl>
        <FormControl sx={{ width: '35%' }}>
          <Autocomplete
            disablePortal
            id='auto-complete-sectors'
            options={sectors ?? []}
            getOptionLabel={(option) => option.sector_name}
            value={sectors?.find((s) => s.sector_code === plotPayload?.sector_code) ?? null}
            fullWidth
            onChange={(e, value) =>
              setPlotPayload({ ...plotPayload, sector_code: value?.sector_code ?? '' })
            }
            renderInput={(params) => <TextField {...params} label={t('sector')} />}
          />
        </FormControl>
      </Stack>

      <Stack direction='row' spacing={4} marginTop={4}>
        <FormControl sx={{ width: '35%' }}>
          <TextField
            label={t('longitudeY')}
            value={centroid?.longitude ?? 0}
            onChange={(e) =>
              centroid
                ? setCentroid({ ...centroid, longitude: parseFloat(e.target.value) })
                : setCentroid({
                    longitude: parseFloat(e.target.value),
                    latitude: 0,
                  } as CentroidData)
            }
            name='numberformat'
            id='formatted-numberformat-input'
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </FormControl>
        <FormControl sx={{ width: '35%' }}>
          <TextField
            label={t('latitudeX')}
            value={centroid?.latitude ?? 0}
            onChange={(e) =>
              centroid
                ? setCentroid({ ...centroid, latitude: parseFloat(e.target.value) })
                : setCentroid({
                    longitude: 0,
                    latitude: parseFloat(e.target.value),
                  } as CentroidData)
            }
            name='numberformat'
            id='formatted-numberformat-input'
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </FormControl>
      </Stack>
    </Box>
  );
};

export default PlotCreationGeoLocalization;
