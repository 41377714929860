import { FormControl, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useGetAdditionalAttributeQuery } from '../../../additionalAttribute/store/additionalAttributeApi';

export type Attributes = { [key: string]: string };

type Props = {
  selectedAttributes: Attributes | null;
  setSelectedAttribute: Dispatch<SetStateAction<{ [key: string]: string } | null>>;
};

const PlotCreationAttribute = ({ selectedAttributes, setSelectedAttribute }: Props) => {
  const { data: attributes } = useGetAdditionalAttributeQuery();

  useEffect(() => {
    if (selectedAttributes == null && attributes) {
      const attributeNames = attributes.reduce((acc: { [key: string]: string }, item) => {
        acc[item.additional_attribute_code] = '';
        return acc;
      }, {});
      setSelectedAttribute(attributeNames);
    }
  }, [selectedAttributes, attributes]);

  return (
    <>
      {attributes &&
        selectedAttributes &&
        attributes.map((attribute) => (
          <FormControl key={attribute.additional_attribute_code}>
            <TextField
              value={selectedAttributes[attribute.additional_attribute_code]}
              placeholder={attribute.additional_attribute_unit}
              sx={{ width: 300 }}
              label={attribute.additional_attribute_name}
              onChange={(e) =>
                selectedAttributes !== null &&
                setSelectedAttribute({
                  ...selectedAttributes,
                  [attribute.additional_attribute_code]: e.target.value,
                })
              }
            ></TextField>
          </FormControl>
        ))}
    </>
  );
};

export default PlotCreationAttribute;
