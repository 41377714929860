import { CustomerData } from 'library/models/customer';
import { PlotDataCreation } from 'library/models/plot';
import { CentroidData } from 'library/models/plotCreation.request';
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react';
import { Attributes } from '../../plotCreationComponents/PlotCreationAttribut';
import { IsChecked } from '../../plotUpdate/PlotUpdateStepOne';
import { usePlotProvider } from './usePlotProvider';

export type PlotProvider = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  isValid: IsChecked | undefined;
  setIsValid: Dispatch<SetStateAction<IsChecked | undefined>>;
  selectedAttributes: Attributes | null;
  setSelectedAttribute: Dispatch<SetStateAction<Attributes | null>>;
  center: string;
  isChecked: { internal_code: boolean; external_code: boolean } | undefined;
  onPostPlotDatabase: () => void;
  plotPayload: PlotDataCreation;
  setPlotPayload: Dispatch<SetStateAction<PlotDataCreation>>;
  isDisabled: () => boolean;
  selectedService: string[];
  setSelectedService: Dispatch<SetStateAction<string[]>>;
  validate: () => void;
  skip: boolean;
  setCustomer: Dispatch<SetStateAction<CustomerData | null>>;
  customer: CustomerData | null;
  isSnackbarError: boolean;
  isSnackBarSuccess: boolean;
  setIsSnackbarError: Dispatch<SetStateAction<boolean>>;
  setSnackBarSuccess: Dispatch<SetStateAction<boolean>>;
  centroid: CentroidData;
  handleUpdatePlot: () => void;
  isUpdateSuccess: boolean;
  setCentroid: Dispatch<SetStateAction<CentroidData>>;
  setSelectedPlotGroup: Dispatch<
    SetStateAction<{
      [keyof: string]: boolean | undefined;
    }>
  >;
  selectedPlotGroup: {
    [keyof: string]: boolean | undefined;
  };
};

export const PlotContext = React.createContext({} as PlotProvider);

export function PlotProviderComponent({ children }: PropsWithChildren): React.ReactElement {
  const provider = usePlotProvider();
  return <PlotContext.Provider value={provider}>{children}</PlotContext.Provider>;
}

export const usePlotContext = (): PlotProvider => {
  const context = useContext(PlotContext);
  return context;
};
