import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { roleTypes } from '../../../auth/roleTypes';
import { UseIsAuthorized } from '../../../auth/useHasAuthorisedBy';
import { utils } from '../../utils/utils';
import { usePlotContext } from '../plotCreation/hooks/usePlotCreation';
import PlotCreationTabComponent from './PlotCreationTabComponent';

const PlotCreationModalContainer = () => {
  const {
    handleClose,
    open,
    handleOpen,
    skip,
    validate,
    isSnackBarSuccess,
    isSnackbarError,
    setSnackBarSuccess,
    setIsSnackbarError,
    isChecked,
    plotPayload,
  } = usePlotContext();
  const { t } = useTranslation();
  const hasAuthorisationToCreate = UseIsAuthorized([roleTypes.ADMIN, roleTypes.EDITOR]);

  const validCheck = !isChecked?.external_code && !isChecked?.internal_code;
  const isDisabled = utils.isPlotPayloadDisabled(plotPayload) || !validCheck;

  return (
    <>
      <Button
        onClick={() => handleOpen()}
        disabled={!hasAuthorisationToCreate}
        startIcon={<AddIcon />}
      >
        Créer une nouvelle parcelle
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose} maxWidth={'xl'}>
        <DialogTitle>Formulaire de la parcelle</DialogTitle>
        <DialogContent>
          <PlotCreationTabComponent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={validate} disabled={isDisabled}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarError}
        onClose={() => setIsSnackbarError(false)}
        autoHideDuration={3000}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          {t('error.plot.creation')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSnackBarSuccess}
        onClose={() => setSnackBarSuccess(false)}
        autoHideDuration={3000}
      >
        <Alert severity='success' sx={{ width: '100%' }}>
          {t('success.plot.creation')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PlotCreationModalContainer;
