import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImportstepOne from './ImportStepOne';
import ImportStepTwo from './ImportStepTwo';

type Props = {
  isOpen: boolean;
  setHandleClose: Dispatch<SetStateAction<boolean>>;
};

const PlotImportModal = ({ isOpen, setHandleClose }: Props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);

  const onClose = () => {
    setHandleClose(false);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const hasStep = () => {
    switch (step) {
      case 0:
        return <ImportstepOne nextStep={nextStep} />;
      case 1:
        return <ImportStepTwo nextStep={nextStep} />;
      default:
        return <>Error</>;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Import des parcelles</DialogTitle>
        <DialogContent> {hasStep()}</DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>{t('button.cancel')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlotImportModal;
