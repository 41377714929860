import { Button, Container, FormControl, Stack, TextField, Typography } from '@mui/material';
import { PlotDataCreation } from 'library/models/plot';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckExistQuery } from '../../store/plotApi';
import DisplayLastPlots from '../DisplayLastPlots';

type Props = {
  plotPayload: PlotDataCreation;
  setPlotPayload: React.Dispatch<React.SetStateAction<PlotDataCreation>>;
  handlePostPlotToDatabase: () => void;
};

const PlotUpdateAddCodes = (props: Props) => {
  const { t } = useTranslation();
  const { plotPayload, setPlotPayload } = props;
  const [skip, setSkip] = React.useState<boolean>(true);
  const [isSelected, setIsSelected] = React.useState<boolean | null>(null);

  const { data: isChecked } = useCheckExistQuery(
    {
      vintage: plotPayload.vintage.toString(),
      internal_plot_code: plotPayload.internal_plot_code ?? '',
      external_plot_code: plotPayload.external_plot_code,
      customer_id: plotPayload?.solution_code ?? '',
    },
    {
      skip: skip,
    }
  );

  useEffect(() => {
    if (
      plotPayload.vintage &&
      plotPayload.external_plot_code &&
      plotPayload.internal_plot_code &&
      plotPayload?.solution_code &&
      isSelected !== null &&
      !isSelected
    ) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [
    plotPayload.external_plot_code,
    plotPayload.internal_plot_code,
    plotPayload.vintage,
    plotPayload?.solution_code,
    isSelected,
  ]);

  return (
    <Container
      sx={{
        margin: 2,
        padding: 2,
      }}
    >
      <Stack direction={'column'} spacing={2}>
        <Stack direction='row' spacing={4} flex={'wrap'}>
          <FormControl sx={{ m: 1, width: '35%', minWidth: '180px' }}>
            <TextField
              error={isChecked?.internal_code}
              label={t('solutionCode')}
              onFocus={() => setIsSelected(true)}
              onBlur={() => setIsSelected(false)}
              value={plotPayload?.internal_plot_code ?? ''}
              onChange={(e) =>
                setPlotPayload({ ...plotPayload, internal_plot_code: e.target.value })
              }
            />
            {isChecked?.internal_code && (
              <Typography mt={1} color='red' align='right' fontSize={9}>
                {t('code.already.used')}
              </Typography>
            )}
            <DisplayLastPlots
              value='internal_plot_code'
              customerId={plotPayload?.solution_code ?? ''}
              vintage={plotPayload.vintage}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '35%', minWidth: '180px' }}>
            <TextField
              error={isChecked?.external_code}
              label={t('externalCode')}
              onFocus={() => setIsSelected(true)}
              onBlur={() => setIsSelected(false)}
              value={plotPayload?.external_plot_code ?? ''}
              onChange={(e) =>
                setPlotPayload({ ...plotPayload, external_plot_code: e.target.value })
              }
            />
            {isChecked?.external_code && (
              <Typography mt={1} color='red' align='right' fontSize={9}>
                {t('code.already.used')}
              </Typography>
            )}
            <DisplayLastPlots
              value='external_plot_code'
              customerId={plotPayload?.solution_code ?? ''}
              vintage={plotPayload.vintage}
            />
          </FormControl>
        </Stack>
        <Button
          sx={{ maxWidth: '300px', alignSelf: 'flex-end' }}
          variant='contained'
          color='primary'
          disabled={isChecked?.external_code || isChecked?.internal_code}
          onClick={props.handlePostPlotToDatabase}
        >
          {t('button.validate')}
        </Button>
      </Stack>
    </Container>
  );
};

export default PlotUpdateAddCodes;
