import { Box, Button, Container, Dialog, MenuItem, Select, Stack } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import LoadingScreen from '../../components/loading-screen';
import { useRenewPlotsCountMutation, useRenewPlotsMutation } from '../store/plotApi';
import { utils } from '../utils/utils';

type Props = {
  open: boolean;
  onClose: () => void;
  number: number;

  selectedPlots: { [key: string]: boolean };
};
const PlotDialogRenewConfirmation = ({ open, onClose, selectedPlots }: Props) => {
  const [validate, setValidate] = useState<boolean>(false);
  const handleRenew = () => {
    onRenewSelectedPlots();
    setValidate(true);
  };
  const [plotCount, setPlotCount] = useState<number | null>(null);
  const [renewPlotsCount] = useRenewPlotsCountMutation();
  const countSelectPlots = Object.keys(selectedPlots).length;
  const [vintage, setVintage] = useState<string | number>(new Date().getFullYear());
  const returnYearsOptions = utils.returnYearsOptions();

  const [renewPlots] = useRenewPlotsMutation();

  const onRenewSelectedPlots = () => {
    const selectedPlotsId: string[] = [];
    for (const key in selectedPlots) {
      if (selectedPlots[key]) {
        selectedPlotsId.push(key);
      }
    }
    renewPlots({ plots: selectedPlotsId, vintage: vintage.toString() });
  };

  useEffect(() => {
    if (open) {
      setValidate(false);
      const selectedPlotsId: string[] = [];
      for (const key in selectedPlots) {
        if (selectedPlots[key]) {
          selectedPlotsId.push(key);
        }
      }

      renewPlotsCount({ plots: selectedPlotsId, vintage: vintage.toString() }).then((data) => {
        // @ts-ignore-ignore
        setPlotCount(data['data'] as number);
      });
    }
  }, [open]);

  if (plotCount === null) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Container>
          {validate ? (
            <Stack spacing={8} m={8} justifyContent='center' alignItems='center'>
              <Box sx={{ fontWeight: 'bold' }}>
                {plotCount} {t('information.plot.renew')} {vintage}
              </Box>
              <Button variant='contained' onClick={onClose}>
                {t('button.close')}
              </Button>
            </Stack>
          ) : (
            <Stack spacing={8} m={8} justifyContent='center' alignItems='center'>
              <Box ml={2}>
                <Select
                  size='small'
                  value={vintage ?? new Date().getFullYear()}
                  onChange={(e) => setVintage(e.target.value)}
                >
                  {returnYearsOptions.map((year) => {
                    return (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {countSelectPlots} {t('information.plot.total.renew')}
              </Box>
              <Box sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {plotCount} {t('information.plot.will.renew')} {vintage}.
              </Box>
              <Box sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {countSelectPlots - plotCount} {t('information.plot.not.renew')} {vintage}
                {t('information.plot.not.renew.end')}
              </Box>
              <Stack direction='row' spacing={2}>
                <Button variant='contained' onClick={() => onClose()}>
                  {t('button.cancel')}
                </Button>
                <Button
                  variant='contained'
                  onClick={() => handleRenew()}
                  disabled={plotCount === 0}
                >
                  {t('button.validate')}
                </Button>
              </Stack>
            </Stack>
          )}
        </Container>
      </Dialog>
    </>
  );
};

export default PlotDialogRenewConfirmation;
