import { configureStore } from '@reduxjs/toolkit';

// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import { additionalAttributeAPi } from '../additionalAttribute/store/additionalAttributeApi';
import { authReducer } from '../auth/store/AuthReducer';
import { configurationSlice } from '../configuration/store/slice';
import { customerApi } from '../customers/customerApi';
import { farmApi } from '../farms/store/famsApi';
import { createFarmSlice } from '../farms/store/slice';
import { grapeVarietyApi } from '../grapeVariety/store/grapeVarietyApi';
import { plotApi } from '../plots/store/plotApi';
import { referentielApi } from '../referentiel/store/referentielApi';
import { sectorApi } from '../sector/store/apiSector';
import { serviceApi } from '../services/serviceApi';

export const store = configureStore({
  reducer: {
    ['authentication']: authReducer,
    [plotApi.reducerPath]: plotApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [farmApi.reducerPath]: farmApi.reducer,
    [grapeVarietyApi.reducerPath]: grapeVarietyApi.reducer,
    [sectorApi.reducerPath]: sectorApi.reducer,
    [referentielApi.reducerPath]: referentielApi.reducer,
    createFarmSlice: createFarmSlice.reducer,
    configurationSlice: configurationSlice.reducer,
    [additionalAttributeAPi.reducerPath]: additionalAttributeAPi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      plotApi.middleware,
      customerApi.middleware,
      farmApi.middleware,
      grapeVarietyApi.middleware,
      sectorApi.middleware,
      referentielApi.middleware,
      additionalAttributeAPi.middleware,
      serviceApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
