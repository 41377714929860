import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Service } from 'library/plots/models/services.entity';
import { authPrepareHeaders } from '../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../config-global';

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  tagTypes: ['services'],
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders,
  }),
  endpoints: (builder) => ({
    getAllActiveService: builder.query<Service[], void>({
      query: () => 'services',
      providesTags: ['services'],
    }),
  }),
});

export const { useGetAllActiveServiceQuery } = serviceApi;
