import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/material';
import { PlotData } from 'library/models/plot';
import { DataToPlotsResult, PlotsError } from '../../services/dataToPlots';
const row = (plot: PlotData) => {
  return <></>;
};

const errorRow = (err: PlotsError) => {
  return (
    <>
      <Box>
        <WarningAmberIcon /> Ligne: {err.line} - Valeur: {err.value}
      </Box>
    </>
  );
};

const ImportValidation = ({ plots, errors }: DataToPlotsResult) => {
  return (
    <>
      {plots.length > 0 ? (
        <>
          <CheckCircleOutlineIcon /> {plots.length} seront importé !
        </>
      ) : (
        <>
          <ErrorIcon /> Vérifier votre import de parcelle.
        </>
      )}
      {errors.map((err) => errorRow(err))}
    </>
  );
};

export default ImportValidation;
