import { Box, Container } from '@mui/material';
import { PlotData } from 'library/models/plot';
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GuardComponent from '../../auth/components/GuardComponent';
import { roleTypes } from '../../auth/roleTypes';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import Page404 from '../../pages/Page404';
import { useGetReferentielsQuery } from '../../referentiel/store/referentielApi';
import SplitButton from './ButtonSelect';
import UpdatePlot from './UpdatePlot';

type Props = {
  beforeButton?: React.ReactElement;
  buttonTitle: string;
  onButtonClick?: () => void;
  title: string;
  plots: PlotData[];
  error: boolean;
  isLoading: boolean;
  disabledButton?: boolean;
  enableSelection?: boolean;
  selectedPlots: { [key: string]: boolean };
  setSelectedPlots: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  allowedRoles: roleTypes[];
};

const PlotsList = (props: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { setSelectedPlots, plots, error, isLoading, enableSelection, selectedPlots } = props;
  const { data: plotGroups } = useGetReferentielsQuery();

  const columns = useMemo<MRT_ColumnDef<PlotData>[]>(
    () => [
      {
        header: t('table.header.farm.actions'),
        accessorKey: 'actions',
        accessor: 'actions',
        accessorFn: (row) => row,
        Cell: (element) => (
          <GuardComponent allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR, roleTypes.VIEWER]}>
            <UpdatePlot plotId={element.row.original.plot_id} />
          </GuardComponent>
        ),
      },
      {
        header: 'Nom du client',
        accessorKey: 'customer_name',
        accessorFn: (row) => row.customer.customer_name,
      },
      {
        header: 'Code client CEGID',
        accessorKey: 'cegid_code',
        accessorFn: (row) => row.customer.cegid_code,
      },
      {
        header: 'Nom du site',
        accessorKey: 'solution_site_name',
        accessorFn: (row) => row.customer.solution_site_name,
      },
      {
        header: 'Code laboratoire du site',
        accessorKey: 'solution_code',
        accessorFn: (row) => row.solution_code,
      },
      {
        header: 'Code parcelle ICV',
        accessorKey: 'internal_plot_code',
        accessorFn: (row) => row.internal_plot_code,
      },
      {
        header: 'Code parcelle client',
        accessorKey: 'external_plot_code_client',
        accessorFn: (row) => row.external_plot_code,
      },
      {
        header: 'Nom parcelle/Lieu-dit',
        accessorKey: 'external_plot_name',
        accessorFn: (row) => row.external_plot_name,
      },
      {
        header: 'Nom exploitation',
        accessorKey: 'farm_name',
        accessorFn: (row) => row.farm.farm_name,
      },
      {
        header: 'Nom cépage',
        accessorKey: 'cepage_code',
        accessorFn: (row) => row.cepage_code,
      },
      {
        header: 'Surface (ha)',
        accessorKey: 'surface_ha',
        accessorFn: (row) => row.surface_ha,
      },
      {
        header: 'Nom secteur',
        accessorKey: 'sector',
        accessorFn: (row) => {
          return 'TODO';
        },
      },
      {
        header: 'Commune)',
        accessorKey: 'municipality',
        accessorFn: (row) => row.municipality,
      },
      {
        header: 'Code postal',
        accessorKey: 'zip_code',
        accessorFn: (row) => row.zip_code,
      },
      {
        header: 'Service(s) associé(s) à la parcelle',
        accessorKey: 'null',
        accessorFn: (row) => row.cepage_code,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    enableRowSelection: enableSelection,
    selectAllMode: 'all',
    state: { rowSelection: selectedPlots },
    enableFullScreenToggle: false,
    localization: MRT_Localization_FR,
    columns,
    data: plots || [],
    getRowId: (row) => row.plot_id, //give each row a more useful id
    onRowSelectionChange: setSelectedPlots, //connect internal row selection state to your own
  });

  if (error) {
    return <Page404 />;
  }

  if (isLoading || !plots || !plotGroups) {
    return <LoadingScreen />;
  }
  console.log('plotGroups44', plotGroups);

  return (
    <Container>
      <Box display='flex' flexDirection={'row'} justifyContent={'right'}>
        <Box p={1} display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
          {props.beforeButton}
          {
            <Box ml={2} width={1}>
              <SplitButton
                selectedPlots={selectedPlots}
                setSelectedPlots={setSelectedPlots}
                plots={plots ?? []}
              />
            </Box>
          }
        </Box>
      </Box>
      <MaterialReactTable table={table} />
    </Container>
  );
};

export default PlotsList;
