// no props unique component to import

import { PlotProviderComponent } from '../plotCreation/hooks/usePlotCreation';
import PlotCreationModalContainer from './PlotCreationModalContainer';

const PlotCreationComponent = () => {
  return (
    <PlotProviderComponent>
      <PlotCreationModalContainer />
    </PlotProviderComponent>
  );
};

export default PlotCreationComponent;
