// ----------------------------------------------------------------------

import { translationFarmFR } from '../../farms/FarmTranslation';
import { plotFr } from '../../plots/translation/plot.Fr';
// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  demo: {
    title: `France`,
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: `Bonjour`,
    description: `Besoin d'aide? \n Consultez notre documentation.`,
    documentation: `documentation`,
  },
  delete: 'Supprimer',
  cancel: 'Annuler',
  next: 'Suivant',
  previous: 'Précédent',
  validate: 'Valider',
  referential: '3. Affectation à un référentiel',
  geographicCoordinates: '4. Données géographiques',
  latitudeX: 'Latitude',
  longitudeY: 'Longitude',
  contour: 'Contour',
  generalInformation: '1. Informations générales',
  customers: 'Clients',
  'farms.title': 'Les Exploitations',
  detailsInformations: '2. Informations détaillées',
  solutionCode: 'Code interne',
  plotName: 'Lieux dit / Nom de la parcelle',
  cepage: 'Cépage',
  'cepages.title': 'Les Cépages',
  millesim: 'Millésime',
  surface: 'Surface (ha)',
  sector: 'Secteur',
  town: 'Commune',
  externalCode: 'Code externe',
  plots: 'Parcelles',
  customer_code: 'Code client',
  active: 'Parcelles actives',
  vintage: 'Millésime',
  center: 'Centre',
  referentiel: 'Référentiel',
  inactive: 'Parcelles inactives',
  'code.already.used': 'Ce code est déjà utilisé',

  //Table headers:
  'table.header.cepage.shortName': 'Nom court',
  'table.header.cepage.fullName': 'Nom complet',
  'table.header.cepage.firstDenomination': 'Première dénomination',
  'table.header.cepage.color': 'Couleur',

  'table.header.farm.name': 'Nom',
  'table.header.farm.address': 'Adresse',
  'table.header.farm.zipCode': 'Code postal',
  'table.header.farm.town': 'Municipalité',
  'table.header.farm.country': 'Pays',

  'table.header.customer.name-solution': 'Nom - Site',
  'table.header.customer.center': 'Centre',
  'table.header.customer.solution_code': 'Code interne',
  'table.header.customer.address': 'Adresse',
  'table.header.customer.zipCode': 'Code postal',
  'table.header.customer.town': 'Municipalité',
  'customers.refreshlist': 'Rafraîchir la liste des clients',

  //Buttons
  'button.parcelle.update.update': 'Enregistrer',
  'button.parcelle.update.delete': 'Supprimer la parcelle',
  'button.parcelle.update.enable': 'Activer la parcelle',
  'button.parcelle.update.disable': 'Désactiver la parcelle',

  'button.plotValidation.add.newPlot': 'Créer une nouvelle parcelle',
  'button.plotValidation.returnToPlotList': 'Retour à la liste des parcelles',

  //Loading messages:
  'loading.data': 'Chargement des données...',

  //Success messages:
  'success.plot.creation': 'Parcelle créée avec succès',

  // Error messages:
  'error.plot.creation': 'Erreur lors de la création de la parcelle',
  'error.load': "Une erreur s'est produite lors du chargement des données",
  'error.gouvApi': "Une erreur s'est produite lors de l'appel à l'API Gouv",
  '404.title': 'Oups !',
  '404.subTitle': 'Les informations que vous recherchez sont introuvables...',
  GoBack: "Retourner à l'accueil",
  '403.subTitle': 'Si vous pensez que c’est une erreur, contactez votre administrateur Oenobox',
  '403.title': 'Vous n’avez pas accès à l’outil Oenobox parcelles.',
  ...plotFr,
  ...translationFarmFR,
};

export default fr;
