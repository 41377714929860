import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { usePlotContext } from '../plotCreation/hooks/usePlotCreation';
import FirstTabContainer from './FirstTab/FirstTabContainer';
import PlotCreationAttribut from './PlotCreationAttribut';
import PlotCreationGeoLocalization from './PlotCreationGeoLocalization';
import PlotCreationServices from './plotCreationServices';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const SwithComponent = (value: number) => {
  const { selectedAttributes, setSelectedAttribute } = usePlotContext();
  return (
    <>
      <CustomTabPanel value={value} index={0}>
        <FirstTabContainer />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PlotCreationAttribut
          selectedAttributes={selectedAttributes}
          setSelectedAttribute={setSelectedAttribute}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <PlotCreationServices />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <PlotCreationGeoLocalization />
      </CustomTabPanel>
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PlotCreationTabComponent = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Identification de la parcelle' {...a11yProps(0)} />
          <Tab label='Informations agronomiques' {...a11yProps(0)} />
          <Tab label='Offre de service ICV' {...a11yProps(0)} />
          <Tab label='Géolocalisation' {...a11yProps(0)} />
        </Tabs>
      </Box>
      {SwithComponent(value)}
    </Box>
  );
};

export default PlotCreationTabComponent;
