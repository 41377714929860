import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingScreen from '../../../components/loading-screen';
import { StepUpdateDialog } from '../../enums/enums';
import PlotsUtils from '../../services/PlotsUtils';
import { useGetPlotQuery } from '../../store/plotApi';
import { usePlotContext } from '../plotCreation/hooks/usePlotCreation';
import PlotCreationTabComponent from '../plotCreationComponents/PlotCreationTabComponent';
import PlotUpdateAddCodes from '../plotUpdate/PlotUpdateAddCodes';
import PlotUpdateConfirmationScreen from '../plotUpdate/PlotUpdateConfirmationScreen';
import PlotUpdateSucceed from '../plotUpdate/PlotUpdateSucceed';

type Props = {
  plotId: string;
};
const PlotUpdateModalContainer = ({ plotId }: Props) => {
  const [step, setStep] = useState<StepUpdateDialog>(StepUpdateDialog.FORM);

  const {
    handleClose: closeDIalog,
    handleOpen,
    open,
    setPlotPayload,
    setCentroid,
    setSelectedAttribute,
    setSelectedPlotGroup,
    plotPayload,
    selectedPlotGroup,
    onPostPlotDatabase,
    handleUpdatePlot,
    isUpdateSuccess,
  } = usePlotContext();

  const { data, isSuccess } = useGetPlotQuery(plotId, {
    skip: !open,
  });

  const handleClose = () => {
    setStep(StepUpdateDialog.FORM);
    closeDIalog();
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      setStep(StepUpdateDialog.SUCCEED);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isSuccess && data) {
      setPlotPayload({
        ...data.plot,
      });

      console.log('attributes', data.attributes);

      const attributeNames = data.attributes.reduce((acc: { [key: string]: string }, item) => {
        acc[item.additional_attributes] = item.attribute_value;
        return acc;
      }, {});
      setSelectedAttribute(attributeNames);

      if (data.centroid) {
        const point = PlotsUtils.wktTOPoint(data.centroid.centroid ?? '');
        setCentroid({
          longitude: point.longitude,
          latitude: point.latitude,
          type: 'Point',
        });
      }

      const selectedPlotGroup: { [keyof: string]: boolean | undefined } = {};
      data.group.forEach((plotGroup) => {
        selectedPlotGroup[plotGroup.plot_group_code] = true;
      });
      setSelectedPlotGroup(selectedPlotGroup);
    }
  }, [isSuccess]);

  const handleUpdatePlotToDatabase = (isNewPlot: boolean) => {
    if (!selectedPlotGroup) return;
    if (plotPayload) {
      if (isNewPlot) {
        setStep(StepUpdateDialog.NEW_PLOT);
      } else {
        handleUpdatePlot();
      }
    }
  };

  const validate = () => {
    setStep(StepUpdateDialog.CONFIRMATION);
  };

  const displayScreen = () => {
    switch (step) {
      case StepUpdateDialog.FORM:
        return <PlotCreationTabComponent />;
      case StepUpdateDialog.CONFIRMATION:
        return (
          <PlotUpdateConfirmationScreen handleUpdatePlotToDatabase={handleUpdatePlotToDatabase} />
        );
      case StepUpdateDialog.NEW_PLOT:
        return plotPayload ? (
          <PlotUpdateAddCodes
            plotPayload={plotPayload}
            setPlotPayload={setPlotPayload}
            handlePostPlotToDatabase={onPostPlotDatabase}
          />
        ) : null;
      case StepUpdateDialog.SUCCEED:
        return <PlotUpdateSucceed handleClose={handleClose} />;
      case StepUpdateDialog.ERROR:
        return <></>;
      default:
        return <></>;
    }
  };

  if (!isSuccess && open) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <EditIcon />
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose} maxWidth={'xl'}>
        <DialogTitle>Formulaire de la parcelle</DialogTitle>
        <DialogContent>{displayScreen()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          {step === StepUpdateDialog.FORM && <Button onClick={validate}>Valider</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlotUpdateModalContainer;
